const isEqual = require('lodash.isequal')

function handlebarsHelper(object, array, options)
{
  const exists = array.filter((element) =>
  {
    return isEqual(object, element)
  })

  if(exists.length !== 0)
    return options.fn(this)
  else
    return options.inverse(this)
}

module.exports = handlebarsHelper
