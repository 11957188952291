/**
 * Convert a number into a string, keeping the specified decimals:
 * @param {number} number - Number
 * @param {number} decimals - Number of decimals
 * @returns {string} The date formatted
 */
function handlebarsHelper(number, decimals)
{
  if(!isNaN(number))
    return number.toFixed(decimals)
  else
    return number
}

module.exports = handlebarsHelper
