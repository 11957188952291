/**
 * Returns the key value in the given language
 * @param {string} key - Dictionary key
 * @returns {string} Key value
 */
function handlebarsHelper(key)
{
  return window.localeService.translate(key)
}

module.exports = handlebarsHelper
