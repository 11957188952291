const getJSONValuePath = require('lodash.get')
/**
 * Returns the value for the given key
 * @param {Object} object - JSON object
 * @param {string} path - Value path
 * @returns {any} The date formatted
 */
function handlebarsHelper(object, path)
{
  return getJSONValuePath(object, path)
}

module.exports = handlebarsHelper
