/*eslint-disable */
const
translateHelper  = require('../../../../backend/application/view/handlebars/helper/translate-browser'),
nestedLookup     = require('../../../../backend/application/view/handlebars/helper/nested-lookup'),
numberToFixed    = require('../../../../backend/application/view/handlebars/helper/number-to-fixed'),
exists           = require('../../../../backend/application/view/handlebars/helper/exists')

//Handlebars runtime library with helpers registered
window.Handlebars = require('@borbalher/component-composer/src/handlebars/runtime')

window.Handlebars.registerHelper('numberToFixed', numberToFixed)
window.Handlebars.registerHelper('translate', translateHelper)
window.Handlebars.registerHelper('nestedLookup', nestedLookup)
window.Handlebars.registerHelper('exists', exists)

